$(window).on("load resize",function(e){

	menuWidth();

});


$(".menu > .mega").hover( function( event ) {
	menuWidth();
});

function menuWidth() {

		var pos = $('header > .mid-content').offset();
 


		$(".menu > .mega > .sub-menu").css(
			{"padding-left" : pos.left , "padding-right" : pos.left }
		);


}


$( ".faqs > .qe-faq-toggle" ).click(function() {
  $(this).toggleClass("active");
});

$(document).ready(function(){

	if (screen.width > 1000){

		$('.carousel .slideshow').bxSlider();

	}

});

$(document).on("click touchend",".procedures-grid .col h2",function() {

	var col = $(this).closest('.col');

	console.log('opened')

	col.siblings('.col').removeClass('open')
	col.toggleClass("open");

});


$(document).on("click", ".menu-item-has-children:not(.open)" , function() {

	$(this).toggleClass('open');
	event.preventDefault();
	console.log('prevd');

	$(this).siblings().removeClass('open');

});